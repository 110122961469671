<template>
  <div id="info">
    <div class="header"></div>
    <div class="line2-fu">
      <div class="line2">
        <!-- <div class="line2-1">
                    小珈享学AI自适应学习系统
                </div>
                <div class="line2-2">
                    以人工智能技术为驱动的教育科技公司
                </div>
      <div class="line2-1">
               
        <div class="line2-1-1">    小珈享学AI学习系统，是湖南果实智能科技有限公司自主研发，以高级算法为核心的自适应学习引擎，拥有高度知识产权，并获得国家专利认证。</div>
        <div class="line2-1-1">    大数据是未来教育发展的基础，未来的在线教育机构首先应是大数据机构，因此湖南果实智能科技首先打造面向未来的教学系统——小珈享学AI一对一学习系统。小珈享学AI学习系统以艾宾浩斯遗忘曲线、神经网络技术、自适应技术、脑科学技术、大数据技术等智能算法为基础，通过人机互动的测评、过程分析等，不断地给学生绘制学习画像，确定学生的掌握状态、学习能力等级，精准侦测不同学生知识漏洞，查漏补缺，模拟特级教师给孩子一对一量身定做教育方案，并一对一实施教育。</div>
        <div class="line2-1-1">    晓果智能AI老师，是第二代人工智能自适应学习系统，通过数据分析将知识点进行拆分，并利用AI老师精确的智能画像，对每一个学生的薄弱环节进行针对性辅导，使学生不会把时间浪费在已熟练掌握的知识点上，从而提高学生的学习效率。</div>
      </div>
      <div class="line2-2">
        <img src="@/assets/info/组 126.png" alt="" v-if='!this.showme'>
        <img src="@/assets/info/组 126@2x.png" alt="" v-if='this.showme'>       
      </div> -->
        <div class="line2-ctx">
          <div class="line2">
            <!-- <div class="line2-1">
              湖南果实智能科技有限公司
            </div> -->
            <!-- <div class="line2-2">
              ----始于2013，创新永不止步
            </div> -->
            <div class="line2-3">
              <div class="line2-3-right">
                <div class="rights-1">湖南果实智能科技有限公司</div>
                <div class="rights-2">----始于2013，创新永不止步</div>
                <div class="rights-3">
                  湖南果实智能科技有限公司是一家拥有高度自主核心知识产权的高新技术企业。公司立足于全国前沿教育，致力于未来科技教育现代化解决方案和一站式落地运营服务提供商，多项新技术、新产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。
                </div>
                <div class="rights-4">
                  公司已开发出具有独立知识产权的AI
                  Effect引擎、数学问题模型、语音智能测评等体系，拥有涵盖中小学全年段、全学科的教学资源，知识点短视频30000多个，题库1600万道，依托强大教学资源和技术积累研发出小珈享学，希望通过AI自适应教育教学模式，为实现天下学子享受到更加个性化、科学化、高效化的教学资源和模式，做一个教育公平道路上的践行者。
                </div>
              </div>
              <div class="line2-3-left">
                <img :src="require(`../assets/info/infoBg${aa}.png`)" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line3">
      <div class="line3-title">企业文化</div>
      <div class="line3-content">
        <img src="@/assets/info/culture.png" alt="" v-if="!this.showme" />
        <img src="@/assets/info/culture@2x.png" alt="" v-if="this.showme" />
      </div>
    </div>
    <div class="line4">
      <div class="line4-title">发展历程</div>
      <!-- 时间轴组件 -->
      <time-axis :showme="showme" :obj="objArr"></time-axis>
    </div>
    <div class="line5" @mouseover="isShow = true" @mouseleave="isShow = false">
      <div class="line5-title">企业荣誉</div>
      <div class="line5-1" ref="line5">
        <div
          class="line5-1-item"
          :class="{ active: currentItem5 === index }"
          v-for="(item, index) in line5List"
          :key="item.id"
          @mouseover="currentItem5 = index"
        >
          <img :src="item.src" alt="" />
          {{ item.content }}
        </div>
      </div>
      <!-- 企业荣誉轮播 -->
      <!-- <img
        @click="goPre"
        class="line5-2"
        src="@/assets/info/组 90 拷贝 2(1).png"
        alt=""
        v-if="this.isShowone && !this.showme && isShow"
      />
      <img
        @click="goPre"
        class="line5-2"
        src="@/assets/info/组 90 拷贝 2@2x(1).png"
        alt=""
        v-if="this.isShowone && this.showme && isShow"
      /> -->
      <!-- <img
        @click="goPre"
        class="line5-2-fu"
        src="@/assets/info/组 90 拷贝 3.png"
        alt=""
        v-if="!this.isShowone && !this.showme && isShow"
      />
      <img
        @click="goPre"
        class="line5-2-fu"
        src="@/assets/info/组 90 拷贝 3@2x.png"
        alt=""
        v-if="!this.isShowone && this.showme && isShow"
      /> -->
      <!-- 企业荣誉轮播 -->
      <!-- <img
        @click="goNext"
        class="line5-3"
        src="@/assets/info/组 90 拷贝 2.png"
        alt=""
        v-if="!this.showme && isShow"
      />
      <img
        @click="goNext"
        class="line5-3"
        src="@/assets/info/组 90 拷贝 2@2x.png"
        alt=""
        v-if="this.showme && isShow"
      /> -->
    </div>
    <div class="line6">
      <div class="line6-title">合作伙伴</div>
      <div class="line6-1">
        <div class="line6-1-item" v-for="item in line6List" :key="item.id">
          <img :src="item.src" alt="" />
        </div>
      </div>
    </div>
    <div class="line7">
      <div class="line7-title">联系我们</div>

      <div class="line7-1">
        <div class="line7-1-left">
          <img src="@/assets/info/contactUs.png" alt="" v-if="!this.showme" />
          <img src="@/assets/info/contactUs@2x.png" alt="" v-if="this.showme" />
        </div>
        <div class="line7-1-right">
          <div class="line7-1-right-1">
            <img src="@/assets/info/图层 1829.png" alt="" v-if="!this.showme" />
            <img src="@/assets/info/图层 1829@2x.png" alt="" v-if="this.showme" />
            公司地址：长沙市芙蓉区古汉国际广场1栋11楼
          </div>
          <div class="line7-1-right-1">
            <img src="@/assets/info/图层 1830.png" alt="" v-if="!this.showme" />
            <img src="@/assets/info/图层 1830@2x.png" alt="" v-if="this.showme" />
            官方公众号：小珈享学
          </div>
          <div class="line7-1-right-1">
            <img src="@/assets/info/图层 1831.png" alt="" v-if="!this.showme" />
            <img src="@/assets/info/图层 1831@2x.png" alt="" v-if="this.showme" />
            咨询电话：15616705276
          </div>
          <div class="line7-1-right-1">
            <img src="@/assets/info/图层 1832.png" alt="" v-if="!this.showme" />
            <img src="@/assets/info/图层 1832@2x.png" alt="" v-if="this.showme" />
            公司邮箱：guoshiedu@qq.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeAxis from "./infoComp/timeaxis.vue";

// params:cls [选择器 .class ]
// 获取元素信息
function getBoxInfo(cls) {
  return getDom(cls).getBoundingClientRect();
}

function getDom(cls) {
  return document.querySelector(cls);
}

export default {
  data() {
    return {
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      aa: 0,
      line5List: [], //企业荣誉证书的集合
      currentItem5: 0, //当前证书项
      isShow: 1, //左右切换icon显示开关
      isShowone: false, //line5左切换icon显示亮度
      objArr: [], //传递给子组件消息
      line6List: [], //合作伙伴的集合
      currentItem6: 0, //当前合作伙伴项
      a: 0, //叠加值
    };
  },
  components: {
    timeAxis,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      let a = currentWidth > 1920 ? "" : "";
      this.aa = a;
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.line5List = [
        {
          id: 0,
          src: require(`../assets/info/1-1${a}.png`),
          content: "AAA级企业信用认证",
        },
        {
          id: 1,
          src: require(`../assets/info/1-2${a}.png`),
          content: "中国教育行业最具有影响力品牌",
        },
        {
          id: 2,
          src: require(`../assets/info/1-3${a}.png`),
          content: "2019年度品牌实力教育机构",
        },

        {
          id: 3,
          src: require(`../assets/info/1-4${a}.png`),
          content: "小珈享学AI智能教育教研团队认证",
        },
        {
          id: 4,
          src: require(`../assets/info/1-5${a}.png`),
          content: "中国管理科学研究院教育一等奖",
        },
        {
          id: 5,
          src: require(`../assets/info/1-6${a}.png`),
          content: "中国百强改革创新示范企业",
        },
        {
          id: 6,
          src: require(`../assets/info/1-7${a}.png`),
          content: "中国315消费者可信赖产品",
        },
        {
          id: 7,
          src: require(`../assets/info/1-8${a}.png`),
          content: "中国教育培训行业优秀服务商",
        },
      ];
      // line6图片集合
      (this.line6List = [
        {
          id: 0,
          src: require(`../assets/info/partner1${a}.png`),
        },
        {
          id: 1,
          src: require(`../assets/info/partner2${a}.png`),
        },
        {
          id: 2,
          src: require(`../assets/info/partner3${a}.png`),
        },
        {
          id: 3,
          src: require(`../assets/info/partner4${a}.png`),
        },
        {
          id: 4,
          src: require(`../assets/info/partner5${a}.png`),
        },
        {
          id: 5,
          src: require(`../assets/info/partner6${a}.png`),
        },
        {
          id: 6,
          src: require(`../assets/info/partner7${a}.png`),
        },
        {
          id: 7,
          src: require(`../assets/info/partner8${a}.png`),
        },
        {
          id: 8,
          src: require(`../assets/info/partner9${a}.png`),
        },
        {
          id: 9,
          src: require(`../assets/info/partner10${a}.png`),
        },
        {
          id: 10,
          src: require(`../assets/info/partner11${a}.png`),
        },
        {
          id: 11,
          src: require(`../assets/info/partner12${a}.png`),
        },
        {
          id: 12,
          src: require(`../assets/info/partner13${a}.png`),
        },
        {
          id: 13,
          src: require(`../assets/info/partner14${a}.png`),
        },
        {
          id: 14,
          src: require(`../assets/info/partner15${a}.png`),
        },
        {
          id: 15,
          src: require(`../assets/info/partner16${a}.png`),
        },
        {
          id: 16,
          src: require(`../assets/info/partner17${a}.png`),
        },
        {
          id: 17,
          src: require(`../assets/info/partner18${a}.png`),
        },
      ]),
        //传递给子组件
        (this.objArr = [
          // {
          //   id:0,
          //   date:'2019年8月',
          //   content:[
          //     {
          //       id:0,
          //       day:23,
          //       content:'湖南果实智能科技有限公司成立'
          //     },
          //   ]
          // },
          {
            id: 1,
            date: "2020",
            content: [
              {
                id: 0,
                month: 1,
                content: [
                  {
                    id: 0,
                    content: "小珈享学学生端、教师端、后台管理国家版权局计算机软件著作权",
                  },
                ],
              },
              {
                id: 1,
                month: 3,
                content: [
                  {
                    id: 0,
                    content: "湖南果实智能科技有限公司荣获AAA级信用企业",
                  },
                ],
              },
              {
                id: 2,
                month: 4,
                content: [
                  {
                    id: 0,
                    content: "小珈享学团队荣获国家级课题科研成果一等奖",
                  },
                  { id: 1, content: "字强不息教育集团战略投资小珈享学" },
                ],
              },
              {
                id: 3,
                month: 6,
                content: [
                  {
                    id: 0,
                    content: "湖南果实智能科技有限公司正式获得ICP经营许可证",
                  },
                  {
                    id: 1,
                    content: "小珈享学2.0产品发布会暨种子合伙人会议在杭召开",
                  },
                ],
              },
              {
                id: 4,
                month: 8,
                content: [
                  {
                    id: 0,
                    content: "小珈享学广东首家直营校——东莞恒大校区正式开业",
                  },
                ],
              },
              {
                id: 5,
                month: 9,
                content: [
                  {
                    id: 0,
                    content: "小珈享学加盟校突破100家",
                  },
                ],
              },
              {
                id: 6,
                month: 11,
                content: [
                  {
                    id: 0,
                    content: "小珈享学初中会考王正式上线",
                  },
                ],
              },
              {
                id: 7,
                month: 12,
                content: [
                  {
                    id: 0,
                    content: "湖南果实智能科技有限公司入选“浙江省科技型中小企业”",
                  },
                  {
                    id: 1,
                    content:
                      "小珈享学亮相“粤港澳大湾区校外教育峰会”，并与深圳托管协会签订战略合作协议",
                  },
                  { id: 2, content: "小珈享学智能英语正式上线" },
                  { id: 3, content: "小珈享学加盟校突破200家，遍布150座城市" },
                ],
              },
            ],
          },
          {
            id: 2,
            date: "2021",
            content: [
              {
                id: 1,
                month: 1,
                content: [
                  {
                    id: 0,
                    content: "湖南果实智能科技有限公司正式在股权交易中心挂牌",
                  },
                  { id: 1, content: "小珈享学加盟校突破400家！" },
                  { id: 2, content: "小珈享学荣登央视CCTV全频道" },
                ],
              },
              {
                id: 2,
                month: 2,
                content: [
                  {
                    id: 0,
                    content: "小珈享学再添三项国家计算机软件著作权登记证书",
                  },
                ],
              },
              {
                id: 3,
                month: 3,
                content: [{ id: 0, content: "荣获《中国315消费者可信赖产品》" }],
              },
              {
                id: 4,
                month: 4,
                content: [
                  { id: 0, content: "小珈享学全新3.0正式上线" },
                  { id: 1, content: "小珈享学与CCTV战略合作正式签约" },
                  { id: 2, content: "小珈享学与贝尔安亲签订战略合作协议" },
                  { id: 3, content: "小珈享学加盟校突破500家！" },
                ],
              },
            ],
          },
        ]);
    },
    //后退
    goNext() {
      this.isShowone = true;
      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line5"),
        inBoxInfo = getBoxInfo(".line5-1");

      if (outBoxInfo.right + 50 < inBoxInfo.right) {
        this.a -= 316;
        getDom(".line5-1").style.transform = `translate(${this.a}px)`;
      }
    },
    //前进
    goPre() {
      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line5"),
        inBoxInfo = getBoxInfo(".line5-1");

      if (outBoxInfo.left > inBoxInfo.left) {
        this.a += 316;
        getDom(".line5-1").style.transform = `translate(${this.a}px)`;
      } else {
        this.isShowone = false;
      }
    },
    //轮播图边界值获取
    isEnd() {
      const maxleft = document.querySelector(".line5-1 > :nth-child(1)");
      const maxright = document.querySelector(".line5-1-item:last-child");
      const currentWidth = document.documentElement.clientWidth;
      let result = [
        maxleft.getBoundingClientRect().left,
        maxright.getBoundingClientRect().right - currentWidth,
      ];
      // console.log(result)
      return result;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/info.styl';
</style>
